.popupLoading
{
  position:absolute;
  top:40%;
  left:50%;
  width:1000px;
  height:1px;
  margin-left:-500px;
  margin-top:-1px;
  z-index: 100;
  text-align: center;
  color: #444;
}

.popupLoading h1 {
  font-size: 1000%;
}

@keyframes scale-down-center {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.3);
  }
}

.scale-down-center {
  animation: scale-down-center 10s cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite backwards;
}

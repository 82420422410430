html {
  height: 100%;
  box-sizing: border-box;
}

:root {
  --item-color: #545d57;
  --item-background-color: rgba(255, 255, 255, 0.91);
  --item-background-title-color: rgba(255, 255, 255, 0.7);
  --item-border: #949393 4px solid;
  --item-border-radius: 7px;
  --item-padding: 10px;

  --logo-background-color: rgba(255, 255, 255, 0.91);
  --logo-border: #949393 4px solid;
  --logo-border-radius: 7px;

  --item-button-color: #545d57;
  --item-button-background-color: rgba(255, 255, 255, 0.91);
  --item-button-border: #949393 4px solid;
  --item-button-border-radius: 7px;

  --item-grid-cell-color: #545d57;
  --item-grid-cell-background-color: rgba(255, 255, 255, 0.91);
  --item-grid-cell-border: #949393 2px solid;
  --item-grid-cell-border-radius: 7px;

  --color_default: #ffffff;
  --color_active: #2c8ccd;
  --color_disabled: gray;
  --color_success: #00ff00;
  --color_error: #ff0000;

  --main-background-color: white;

  --bacground_color: black;
}

p, .normal-text {
  font-size: 32px;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  position: relative;
  margin: 0;
  min-height: 100%;
  font-family: "Helvetica Neue", Arial, sans-serif;
}

body {
  background-size: cover;
  height: 100%;
}

body {
  background-color: var(--main-background-color);
}

.main-lite{
  height: 100%;
}

.main, .footer-content, .header-content {
  margin: 0 auto;
  width: 90%;
}

.header-content {
  padding-top: 25px;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  top: 0;
  position: absolute;
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
}

.site-width{
  width: 90%;
}

.header1{
  font-weight: bold !important;
  text-transform: uppercase !important;
  font-family: "Arial Black", Gadget, sans-serif !important;
}

.footer-content{
  padding-top:50px;
  padding-bottom: 50px;
}

.footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;

  pointer-events: none;
}

.footer .buttons {
  pointer-events: auto;
}

.header{
  margin-bottom: 50px;
  background-image:url("/static/images/banner.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 200px;
}

h1 {
  color: #369;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 250%;
}

.btn-item {
  color: var(--item-button-color)!important;
  background-color: var(--item-button-background-color)!important;
  border: var(--item-button-border)!important;
  border-radius:  var(--item-button-border-radius)!important;
}

.button-base{
  white-space:normal !important;
  display: table-cell !important;
  vertical-align: middle;
  font-weight: bold;
  font-size: 32px !important;
  padding: 20px 30px !important;
}

.button-base.button-strict-large{
  width: 600px;
  height: 175px;
  font-size: xx-large !important;
}

.button-base.button-large{
  height: 175px;
  padding-left: 100px !important;
  padding-right: 100px !important;
}

.button-base.button-medium{
  height: 80px;
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.button-base.btn-default,.button-base.btn-primary,.button-base.btn-success,.button-base.btn-info,.button-base.btn-warning,.button-base.btn-danger {
  -webkit-box-shadow: 2px 3px 0px rgba(4, 4, 4, 0.3);
  -moz-box-shadow:    2px 3px 0px rgba(4, 4, 4, 0.3);
  box-shadow:         2px 3px 0px rgba(4, 4, 4, 0.3);
  border-radius:0;
}

.button-base.btn-default:active,.button-base.btn-primary:active,.button-base.btn-success:active,.button-base.btn-info:active,.button-base.btn-warning:active,.button-base.btn-danger:active {
  margin-top: 3px;
  margin-bottom: -3px;
}

.button-base.back-button{
  width: 355px;
  height: 100px;
}

.row-buffer-bottom {
  margin-bottom: 75px;
}

.entire-width{
  width: 100% !important;
}

.scrollbar::-webkit-scrollbar{
  width: 40px;
}

.scrollbar::-webkit-scrollbar-track{
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb{
  width: 15px;
  background: #c1c1c1;
}

.mat-ink-bar {
  background-color: #337AB7 !important;
}

.mat-tab-label {
  height: 32px !important;
}

.modal {
  text-align: center;
}

.modal-content {
  padding: 15px 25px 25px 25px;
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  width: 1300px !important;
}

.modal-backdrop {
  opacity: 0.5 !important;
}

@media screen and (min-width: 768px) {
  .modal:before {
      display: inline-block;
      vertical-align: middle;
      content: " ";
      height: 100%;
  }
}

.red{
  color: red;
}

.green{
  color: green;
}

.orange{
  color: orange;
}

.browser-warning{
  background-color: red;
  border: solid 1px white;
  color: white;
  font-weight: bold;
  text-align: center;
  padding: 5px;
  margin-bottom: 3px;
}

select {
  height: 33px;
  line-height:33px;
  border: solid 1px #ccc;
  border-radius: 4px;
  padding-left: 10px;
  padding-right: 10px;
}

label {
  font-weight: normal !important;
  margin-right: 5px  !important;
  /* font-size: 0.7em; */
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.ticket-input-data, .ticket-use, .ticket, .basket, .payment-cash, .workflow, .message-outer, .payment-card, .simulator, .money-exchange {
  color: var(--item-color)!important;
  background-color: var(--item-background-color)!important;
  border: var(--item-border)!important;
  border-radius: var(--item-border-radius)!important;
  padding: var(--item-padding)!important;
}

.ticket-use {
  min-height: 500px;
}

.pageHeader h1 span {
  background-color: var(--item-background-title-color)!important;
  border-radius:  var(--item-border-radius)!important;
}

.logo, img.lang {
  background-color: var(--logo-background-color)!important;
  border: var(--logo-border)!important;
  border-radius:  var(--logo-border-radius)!important;
}

.basket-button-container {
  background-color: var(--logo-background-color)!important;
  border: var(--logo-border)!important;
  border-radius:  var(--logo-border-radius)!important;
}

.basket-button-container a {
  color: var(--item-button-color)!important;
}

img.lang {
  padding: 4px;
}

.centered-content {
  background-color: var(--item-background-title-color)!important;
  border-radius:  var(--item-border-radius)!important;
}

.btn-clear-basket {
  font-size: 32px !important;
  width: 85%;
}

.simulator-container {
  position: absolute;
  width: 90%;
  z-index: 100001;
}

.simulator-lite-container {
  margin: 0 5%;
}

.simulator-control {
  position: absolute;
  z-index: 100002;
  right: 5%;

  font-size: 26px;
  color: var(--item-color)!important;
  background-color: var(--item-background-color)!important;
  border: var(--item-border)!important;
  border-radius: 7px!important;
  border-radius: var(--item-border-radius)!important;
  padding-left: 8px;
  padding-right: 8px;

  cursor: pointer;
}

.gradient-black-color {
  stop-color:rgb(0,0,0);
  stop-opacity:1;
}

.gradient-color {
  stop-color:rgb(255,255,255);
  stop-opacity:1;
}

.gradient-active-color {
  stop-color:var(--color_active);
  stop-opacity:1;
}

.gradient-disabled-color {
  stop-color:var(--color_disabled);
  stop-opacity:1;
}

.gradient-success-color {
  stop-color:var(--color_success);
  stop-opacity:1;
}

.svg-default {
  fill: var(--color_default);
  color: var(--color_default);
}

.svg-active {
  fill: var(--color_active);
  color: var(--color_active);
}

.svg-disabled {
  fill: var(--color_disabled);
  color: var(--color_disabled);
}

.svg-success {
  fill: var(--color_success);
  color: var(--color_success);
}

.svg-error {
  fill: var(--color_error);
  color: var(--color_error);
}

.lite-mode {
  background-color: var(--bacground_color);
}

.lite-mode .message h1, .lite-mode .message h2 {
  color: var(--color_default);
}

.svg-maintenance {
  fill: var(--color_error);
  color: var(--color_error);
}

@font-face {
  font-family: Wanzl_EtelkaMedium;
  src: url(/static/font/Wanzl_EtelkaMedium.otf) format("opentype");
}

.grid-cell-container, .grid-cell-container-middle, .grid-cell-button {
  display: flex;
  text-align: center;

  height: 100%;
  width: 100%;

  color: var(--item-grid-cell-color);
  border: var(--item-grid-cell-border);
  border-radius:  var(--item-grid-cell-border-radius);
  background-color: var(--item-grid-cell-background-color);
}

.grid-cell-container-middle, .grid-cell-button {
  justify-content: center;
  flex-direction: column;
}

.grid-cell-container-inner {
  overflow: hidden;
  padding-left: 5px;
  padding-right: 5px;
  width: 100%;
}

.grid-cell-container .grid-cell-container-inner {
  height: 100%;
}

.grid-cell-button {
  box-shadow: 2px 3px 0px rgba(4, 4, 4, 0.3);
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: #2c8ccd9e!important;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: var(--color_active)!important;
}

.mat-slide-toggle-content {
  font-size: 32px;
  padding-right: 20px;
  overflow: unset !important;
  margin-bottom: 3px;
  padding-left: 20px;
  position: relative;
  color: white;
  outline: none;
}

.mat-slide-toggle .mat-slide-toggle-bar {
	position: absolute;
	width: 100%;
	height: 100%;
	padding: 30px;
	margin: auto;
}

.mat-slide-toggle-label {
	margin: unset!important;
	position: absolute!important;
}

.mat-slide-toggle-label-before .mat-slide-toggle-bar{
  margin: unset!important;
}

.mat-slide-toggle-thumb-container {
	display: none!important;
}

.mat-slide-toggle .mat-slide-toggle-content:before {
	content: attr(data-content);
}

.mat-checked .mat-slide-toggle-content:before {
	content: unset!important;
}

.mat-slide-toggle {
  padding-bottom: 30px!important;
}

.row .mat-slide-toggle {
  padding-bottom: 50px!important;
  display: flex!important;
  justify-content: center !important;
}

.center-receipt-switcher {
  transform-origin: 50%;
  font-size: 0.7em;
}

.left-mode-receipt-switcher {
  transform-origin: 0;
  font-size: 0.7em;
}

.footer-button {
  display: inline;
  padding-right: 20px;
  float: left;
}

/* You can add global styles to this file, and also import other style files */

.bs-datepicker {
    z-index: 1050 !important;
    font-size: 36px !important;
}

.bs-datepicker-body table.years span {
    width: 140px !important;
    height: 70px !important;
    line-height: 200% !important;
    border-radius: 0% !important;
}

.bs-datepicker-body table.months span {
    line-height: 200% !important;
    border-radius: 0% !important;
}

.bs-datepicker-body table.days span {
    height: 70px !important;
    width: 70px !important;
    line-height: 200% !important;
    border-radius: 15% !important;
}

.bs-datepicker-body th {
    font-size: 36px !important;
}

.bs-datepicker-body span {
    font-size: 36px !important;
    line-height: 200% !important;
    border-radius: 15% !important;
}

.bs-datepicker-head button {
    max-width: none !important;
    padding: unset !important;
    vertical-align: unset !important;
    height: 100% !important;
    line-height: 25px !important;
}

.bs-datepicker-head {
    height: 80px !important;
}

.bs-datepicker-head button.previous span, .bs-datepicker-head button.next span {
    font-size: 60px !important;
    line-height: unset !important;
}

.bs-datepicker-head button.previous, .bs-datepicker-head button.next {
    border-radius: 15% !important;
    width: 15% !important;
    height: 100% !important;
    padding: 10px !important;
}

.bs-datepicker-head button.current {
    padding: 13px !important;
}

.border-rules {
  border: var(--logo-border);
  border-radius: var(--logo-border-radius);
}
